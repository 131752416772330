import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import { withPreview } from 'gatsby-source-prismic';
import Hero from 'components/hero';

import Container from '@material-ui/core/Container';
import { TitleText, FullImage, FreeContentArea } from 'components/slices';

import { Helmet } from 'react-helmet';

function Article(props) {
  const { data } = props;
  const doc = data.prismicNewsArticle;
  if (!doc) return null;
  return (
    <Layout>
      <Helmet>
        <meta content={doc.data.banner_image.url} property="og:image" />
      </Helmet>
      {doc.data.metadata && doc.data.metadata[0] && (
        <Helmet>
          {doc.data.metadata[0] && doc.data.metadata[0].title && (
            <title>{doc.data.metadata[0].title.text}</title>
          )}
          {doc.data.metadata[0] && doc.data.metadata[0].description && (
            <Helmet>
              <meta
                name="description"
                content={doc.data.metadata[0].description.text}
              />
              <meta
                property="og:description"
                content={doc.data.metadata[0].description.text}
              />
              <meta
                name="twitter:description"
                content={doc.data.metadata[0].description.text}
              />
            </Helmet>
          )}
          {doc.data.metadata[0] && doc.data.metadata[0].keywords && (
            <meta
              name="keywords"
              content={doc.data.metadata[0].keywords.text}
            />
          )}
        </Helmet>
      )}
      <Hero data={doc.data} />
      {doc.data.body && (
        <Container maxWidth="xl">
          {doc.data.body.map((item, i) => {
            switch (item.slice_type) {
              case 'title___text':
                return (
                  <div key={i}>
                    <div className="spacer">
                      <TitleText data={item.primary} />
                    </div>
                  </div>
                );
              case 'full_image_block':
                return (
                  <div key={i}>
                    <div className="spacer">
                      <FullImage data={item.primary} />
                    </div>
                  </div>
                );
              case 'free_content_area':
                return (
                  <div key={i}>
                    <div className="spacer">
                      <FreeContentArea data={item.items} />
                    </div>
                  </div>
                );
              default:
                return null;
            }
          })}
        </Container>
      )}
    </Layout>
  );
}

Article.propTypes = {
  data: PropTypes.object.isRequired,
};

export default withPreview(Article);

export const query = graphql`
  query ArticlePageQuery($id: String!) {
    prismicNewsArticle(id: { eq: $id }) {
      uid
      data {
        metadata {
          description {
            text
          }
          keywords {
            text
          }
          title {
            text
          }
        }
        banner_image {
          url
          alt
        }
        banner_title {
          html
          raw
          text
        }
        title {
          html
          raw
          text
        }
        body {
          ... on PrismicNewsArticleBodyFreeContentArea {
            slice_type
            items {
              content {
                html
                raw
                text
              }
            }
          }
          ... on PrismicNewsArticleBodyTitleText {
            slice_type
            primary {
              content {
                html
                raw
                text
              }
              title {
                html
                raw
                text
              }
            }
          }
          ... on PrismicNewsArticleBodyFullImageBlock {
            slice_type
            primary {
              caption {
                html
                raw
                text
              }
              image {
                alt
                url
              }
              title {
                html
                raw
                text
              }
              link {
                url
                uid
                type
                id
              }
            }
          }
        }
      }
    }
  }
`;
