import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import IconSml from 'images/icon-sml.svg';

export const Background = styled.div`
  background-image: ${props => `url(${props.image})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  height: calc(100vh - 100px);
  position: relative;
  overflow: hidden;
`;

function Hero(props) {
  const { data } = props;
  return (
    <Container maxWidth={false}>
      <Background image={data.banner_image.url}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <Container>
            <Typography
              variant="h1"
              component="h1"
              color="textSecondary"
              align="center"
              className="text-uppercase"
            >
              {data.banner_title.text}
            </Typography>
          </Container>
        </Box>
      </Background>
    </Container>
  );
}

Hero.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Hero;
